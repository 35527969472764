import { render, staticRenderFns } from "./SideArea.vue?vue&type=template&id=66e70ebc&scoped=true&"
import script from "./SideArea.vue?vue&type=script&lang=js&"
export * from "./SideArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e70ebc",
  null
  
)

export default component.exports