<template>
    <div class="col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="article-sidebar">
            <!-- Searchbard -->
            <div class="single-widgets widget_search">
                <h3 class="title">キーワード検索</h3>
                <form v-on:submit.prevent="onClickSearch" class="sidebar-search-form">
                    <input v-model="searchText" type="search" name="search" placeholder="キーワードを入力">
                    <button type="submit"><i class="ti-search" title="検索ボタン"></i></button>
                </form>
            </div>
            <!-- メニュー -->
            <div v-if="existsMenuList" class="single-widgets widget_category">
                <h3 class="title">メニュー</h3>
                <ul>
                    <li><router-link to="/article">SESノウハウ</router-link></li>
                    <li><router-link to="/companylist">SES企業・エージェントリスト</router-link></li>
                </ul>
            </div>
            <!-- タグ一覧 -->
            <div class="single-widgets widget_tags">
                <h3 class="title">{{tagTitle}}</h3>
                <ul>
                    <li v-for="[id, name] in Object.entries(tags)" :key="id"><router-link :to="{ path: '/article', query: { tag: encodeURI(id) }}" class="blog-cates-list">{{name}}</router-link></li>
                </ul>
            </div>
          <!-- Banner Area -->
          <div class="companyListThumb">
            <router-link to="/companylist">
              <img src="assets/img/CompanyListBanner.png" class="home-top-img w-100" alt="企業リストページへのリンク画像" width="330" height="216" loading="lazy" />
            </router-link>
          </div>
          <!--<div class="companyListThumb">
            <a target="_blank" href="https://forms.gle/65T8eXEQ15SHjrSN7">
              <img src="assets/img/MarketerRecruitBanner.png" class="home-top-img" alt="マーケター募集バナー画像" />
            </a>
          </div>-->
          <div class="companyListThumb">
            <router-link to="/register">
              <img src="assets/img/RecommendBanner.png" class="home-top-img" alt="企業アカウントの解説を進める画像" width="330" height="216" loading="lazy" />
            </router-link>
          </div>
          <!--<div class="companyListThumb">
            <a target="_blank" href="https://forms.gle/xCkGCffvauSSQJEP7">
              <img src="assets/img/RequestBanner.png" class="home-top-img" alt="機能改善募集のリンク画像" width="330" height="216" />
            </a>
          </div>-->
          <div class="companyListThumb">
            <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6">
              <img src="assets/img/ArticleAds2.png" class="home-top-img" alt="記事内広告募集のリンク画像" width="330" height="216" loading="lazy" />
            </a>
          </div>
          <div class="companyListThumb">
            <a target="_blank" href="https://forms.gle/AQ4ULJHt99JVvCFT6">
              <img src="assets/img/AdsBanner.png" class="home-top-img" alt="広告掲載募集のリンク画像" width="330" height="216" loading="lazy" />
            </a>
          </div>
          <!-- Banner Area -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "SideArea",
        props: {
            tags: {
                type: Object,
                default: () => {}
            },
            tagTitle: {
                type: String,
                default: "全てのタグ"
            },
            existsMenuList: {
                type: Boolean,
                default: true,
            }
        },
        data: function() {
            return {
                searchText: "",
            }
        },
        created: function () {
        },
        watch: {
        },
        methods: {
            onClickSearch () {
                this.$router.push({ path: '/article', query: { search: encodeURI(this.searchText) } });
            }
        }
    }
</script>

<style scoped>

</style>
