import * as contentful from "contentful";
import {decrypt, isDevelop} from "@/common/utils";

const spaceKey = [
    new Uint8Array([78, 155, 118]),
    new Uint8Array([158, 68, 178]),
    new Uint8Array([85, 171, 75, 166, 105, 135, 114, 188, 99, 205])
];
const accessTokenKey = [
    new Uint8Array([139, 51, 207, 100, 139, 48]),
    new Uint8Array([187, 85, 157, 48, 143, 81, 151]),
    new Uint8Array([77, 136, 57])
];

const previewTokenKey = [
    new Uint8Array([138, 114, 202, 50, 200, 118]),
    new Uint8Array([151, 111, 146, 84, 147, 100]),
    new Uint8Array([151, 101, 198, 84])
];

export function createContentfulClient() {
    let params;
    if (isDevelop()) {
        params = {
            space: decrypt(process.env.VUE_APP_CTF_SPACE_ID, spaceKey),
            accessToken: decrypt('U2FsdGVkX18U7Zf/7+2zg/CMtqpZRuGCY3WHc8Gq4p2YtmmiPTrIIhAYgVK4biRGv8ofW8n4kwf69XKdJCTnWQ==', previewTokenKey),
            host: "preview.contentful.com"
        };
    } else {
        params = {
            space: decrypt(process.env.VUE_APP_CTF_SPACE_ID, spaceKey),
            accessToken: decrypt('U2FsdGVkX19SNKoHCv+1eaOEUhK1RLJk7HEy083mnJ5Tfqqv+doqZIk46rsg5BvnjjuztNtjmVB5M3zaCzGiRw==', accessTokenKey)
        };
    }
    return contentful.createClient(params);
}